
import './App.css';
import Kanye from './Kanye';

function App() {
  return (
    <div className="App">
      <Kanye/>
    </div>
  );
}

export default App;
